import singleSpaVue from 'single-spa-vue';
import { h, createApp } from 'vue';

import App from './App.vue';
import Fetching from './plugins/fetching';
import { i18n } from './plugins/i18n';
import Mitt from './plugins/mitt';
import { pinia } from './plugins/pinia';
import { vuetify } from './plugins/vuetify';
import router from './router';

import '@mdi/font/css/materialdesignicons.css';

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        translations: this.translations,
        arabicTranslation: this.arabicTranslation,
        sentryUrl: this.sentryUrl,
        sentrySampleRate: this.sentrySampleRate,
        ignoredSentryErrors: this.ignoredSentryErrors,
        checkSentryErrors: this.checkSentryErrors,
        isLocal: this.isLocal,
        showFromforPaymentPage: this.showFromforPaymentPage,
        checkoutSDKLink: this.checkoutSDKLink,
      });
    },
  },
  handleInstance(app) {
    app.use(router);
    app.use(vuetify);
    app.use(i18n);
    app.use(pinia);
    app.use(Fetching);
    app.use(Mitt);
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
