<template>
  <div>
    <router-view />
    <Snackbar></Snackbar>
    <Version />
  </div>
</template>

<script setup>
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import Snackbar from './components/Snackbar';
import { i18n } from './plugins/i18n';

import Version from '@/components/Version';
import { useGlobalVariableStore } from '@/store/index';

const props = defineProps([
  'translations',
  'arabicTranslation',
  'sentryUrl',
  'sentrySampleRate',
  'ignoredSentryErrors',
  'checkSentryErrors',
  'isLocal',
  'showFromforPaymentPage',
  'checkoutSDKLink',
]);

Sentry.setTag('microfrontend', 'public_frontend');
Sentry.init({
  dsn:
    props.sentryUrl ||
    'https://f5c94a2ff6af4ec3828f3b3b25bbd929@sentry.ottu.net/10',
  integrations: [new Integrations.Vue({ attachProps: true, logErrors: true })],
  ignoreErrors: props.ignoredSentryErrors,
  beforeSend: props.checkSentryErrors,
  sampleRate: props.sentrySampleRate,
  autoSessionTracking: false,
  enabled: !props.isLocal,
  translations: props.translations,
});

const store = useGlobalVariableStore();
store.setIsShowFromForPaymentPage(props.showFromforPaymentPage);
store.setCheckoutSDKLink(props.checkoutSDKLink);
</script>
<script>
export default {
  components: { Snackbar, Version },
  mounted() {
    this.setLang();
  },
  watch: {
    '$route.params.lang': function (value) {
      if (value === 'en' || value === 'ar') {
        i18n.global.locale = value;
        this.$vuetify.locale.current = i18n.global.locale;
      }
    },
  },
  methods: {
    setLang() {
      const translations = this.translations();
      i18n.global.messages['en'] = translations.en || {};
      i18n.global.messages['ar'] = this.arabicTranslation || {};
    },
  },
};
</script>
<style></style>
